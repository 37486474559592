import React from "react";
import PropTypes from "prop-types";
import Logo from "../atoms/Logo";
import { useRouter } from "next/router";



const BackButton = ({  className = "bg-white px-[50px] py-[25px]", onClick }) => {
  const router = useRouter();

  const handleOnClick = () => {
    onClick ? onClick() : router.back();
  }

  return (
    <div className={className}>
        <Logo
          alt="back logo"
          className=""
          height="40"
          src="/images/Back_Icon.svg"
          width="149"
          id="back-logo"
          onClick={handleOnClick}
        />
      </div>
  );
};

export default BackButton;

BackButton.propTypes = {
  classNme: PropTypes.string,
  
};
