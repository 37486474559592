import classNames from "classnames";
import Image from "next/image";
import React from "react";
import PropTypes from "prop-types";
const buttonStyles = {
  primary: "primary",
  ghost: "ghost",
  borderLess: "borderLess",
  link: "link",
  secondary: "secondary",
  white: "white",
  dark: "dark",
};

const sizes = {
  extraSmall: "extraSmall",
  small: "small",
  medium: "medium",
  large: "large",
  extraLarge: "extraLarge",
};

const Button = React.forwardRef(
  (
    {
      type = "button",
      id = "",
      disabled = false,
      className = "",
      size,
      btnText = "Something",
      style = buttonStyles.primary,
      fullWidth = false,
      onClick = () => { },
      showIcon = false,
      imgSrc = "/images/Arrow-Right.svg",
      imgAlt = "arrow right",
      imgWidth = 16,
      imgHeight = 16,
      fontSize = "text-sm",
      rounded = "rounded-[10px]",
      classForImage = "",
      classForBtnText = 'pt-0.5',
      ...props
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        type={type}
        id={id}
        onClick={onClick}
        disabled={disabled}
        className={classNames(
          [className, fontSize, rounded],
          disabled ? "pointer-events-none opacity-50" : "cursor-pointer",
          "transition-all ease-linear duration-700 whitespace-pre xs:whitespace-normal",
          {
            "text-primary-900 hover:text-primary-800 focus:text-primary-800 hover:bg-primary-100 focus:bg-primary-100 focus:outline focus:outline-primary-800 focus:outline-2 active:bg-primary-200 visited:bg-primary-200":
              style === buttonStyles.borderLess,
            "px-3 py-2": size === sizes.small,
            "px-5 py-2": size === sizes.medium,
            "px-5 py-[18px]": size === sizes.large,
            "px-7 py-5": size === sizes.extraLarge,
            "px-3 py-1": size === sizes.extraSmall,
            "w-full": fullWidth,
            "bg-neutral-900 text-white focus:outline-none active:scale-90":
              style === buttonStyles.primary, //using
            "bg-black text-white  focus:outline-none active:scale-90":
              style === buttonStyles.secondary,
            "bg-black text-secondary-50 focus:outline-none active:scale-90":
              buttonStyles.dark, //using
            "bg-white !text-[#1F262F] border border-neutral-900 focus:outline-none active:scale-90":
              style === buttonStyles.white,
          }
        )}
        {...props}
      >
        <span className={classForBtnText}>{btnText}</span>
        {showIcon && (
          <span className={`flex justify-center items-center flex-shrink-0 ${classForImage}`}>
            <Image
              src={imgSrc}
              alt={imgAlt}
              width={imgWidth}
              height={imgHeight}
            />
          </span>
        )}
      </button>
    );
  }
);

Button.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  btnText: PropTypes.string,
  style: PropTypes.oneOf(Object.values(buttonStyles)),
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  showIcon: PropTypes.bool,
  imgSrc: PropTypes.string,
  imgAlt: PropTypes.string,
  imgWidth: PropTypes.number,
  imgHeight: PropTypes.number,
  fontSize: PropTypes.string,
};
export default Button;
