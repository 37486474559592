import PropTypes from "prop-types";
import React, { forwardRef } from "react";

const Heading = forwardRef(({ children, type = "h1", className = "", fontSize, ...otherProps }, ref) => {

  const HeadingVariants = {
    h1: "h1",
    h2: "h2",
    h3: "h3",
    h4: "h4",
    h5: "h5",
    h6: "h6",
  };

  const HeadingStyle = {
    h1: "text-[50px]",
    h2: "text-[40px]",
    h3: "text-xl md:text-[34px]",
    h4: "text-lg xs:text-xl md:text-3xl",
    h5: "text-2xl",
    h6: "text-base sm:text-xl",
  };
  
  const Type = HeadingVariants[type] ? HeadingVariants[type] : "h1";

  return (
    <Type ref={ref} className={`${className} ${fontSize ? fontSize : HeadingStyle[type]}`} {...otherProps}>
      {children}
    </Type>
  );
});

export default Heading;

Heading.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
};
