import * as HttpService from "./http.service";
import { LOGIN_WITH_EMAIL_URL, VERIFY_OTP_WITH_EMAIL_URL } from "./url.service";

export const loginWithEmail = (data) => {
    return HttpService.postWithOutAuth(LOGIN_WITH_EMAIL_URL, {userData: data});
};


export const verifyOtpWithEmail = (data) => {
    return HttpService.postWithOutAuth(VERIFY_OTP_WITH_EMAIL_URL, {userData: data});
};
