import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Image from "next/image";
import Alert from 'atoms/Alert';

const Modal = ({
  className = "",
  showModal = false,
  overLayClass = "fixed inset-0 z-50",
  handleClickOutside = () => { },
  children,
  classForCard = "",
  closeModel = () => { },
  cardInCenter = false,
  cardBgColor = 'bg-primary-400',
  iconPosition = 'top-[70px] right-[70px]',
  bgColor = 'bg-[#1A2028] bg-opacity-90',
  height = 'h-[100dvh]',
  showCloseIcon = true,
  handleAlertClose = () => { },
  showAlert = false,
  alertMsg,

}) => {

  useEffect(() => {
    let timeoutId;
    if (showAlert) {
      timeoutId = setTimeout(() => {
        handleAlertClose();
      }, 4000);
    }
    return () => clearTimeout(timeoutId);
  }, [showAlert]);

  return (
    showModal && (
      <div
        className={`${height} ${bgColor} ${cardInCenter ? "flex items-center justify-center" : ""
          } ${overLayClass} ${className}`}
        onClick={handleClickOutside}
      >
        {showCloseIcon && <div
          className={`absolute ${iconPosition} cursor-pointer`}
          onClick={closeModel}
        >
          <Image
            src="/images/icons/close.svg"
            alt="close"
            width={23}
            height={23}
          />
        </div>}
        <div
          className={`${cardBgColor} ${classForCard}`}
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </div>

        <Alert
          iconSrc="/images/icons/check-black.svg"
          msg={alertMsg}
          type="type2"
          className={`w-[90%] max-w-[424px] md:w-full absolute transform duration-700 ${showAlert ? 'bottom-10' : '-bottom-full'} left-0 right-0 md:right-auto mx-auto md:left-10`}
          handleClose={handleAlertClose}
        />
      </div>
    )
  );
};

Modal.propTypes = {
  className: PropTypes.string,
  showModal: PropTypes.bool,
  overLayClass: PropTypes.string,
  handleClickOutside: PropTypes.func,
  children: PropTypes.node,
  classForCard: PropTypes.string,
  closeModel: PropTypes.func,
};

export default Modal;

