import Cookies from 'universal-cookie';
import { getPriceWithCurrency } from './helper-function.service';
import axios from "axios";

const cookies = new Cookies();

export const getUserDetails = () => {
    return typeof localStorage !== "undefined"
        ? JSON.parse(localStorage.getItem("user-details"))
        : null;
};

export const getUserCurrency = (amount, currency) => {
    return getPriceWithCurrency({
        price: amount,
        currency
    });
}

export const currencyMapping = {
    DE: 'EUR',
    SA: 'SAR',
    AE: 'AED',
    US: "USD",
};

export const getCurrencyIsoCode = (userCountry) => {
    const countryIsoCode = userCountry || getUserCountry();

    return currencyMapping[countryIsoCode.toUpperCase()];
}

export const getCountryFromCookies = () => (cookies.get('userCountry', { path: '/' }))

export const getUserCountry = () => {
    const country = cookies.get('userCountry', { path: '/' });
    if (country) return country.toUpperCase();
    return "DE"
}

export const setUserCountry = (selectedCountry = 'DE') => {
    cookies.set('userCountry', selectedCountry.toUpperCase(), { path: '/' });
}

export const getUserLocale = () => {
    const locale = cookies.get('userLocale');
    return locale ? locale : 'en';
}

export const setUserLocale = (value) => {
    cookies.set("userLocale", value, { path: '/' });
}

export const setUserCurrencyInCookie = (currency) => {
    cookies.set('userCurrency', currency, { path: '/' });
    return currency;
}


export const getUserCountryQuery = (country) => {
    if (country) return country
    return "DE"
}

export const getProductAmount = ([priceData]) => {
    if (priceData?.BPPriceBook?.currencyIsoCode === 'CDA' || priceData?.BPPriceBook?.currencyIsoCode === 'USD') {
        return Number(priceData.netPriceAmount)
    }
    return Number(priceData.grossPriceAmount)
}

export const getPlanAmount = (planPriceData) => {
    if (!planPriceData || planPriceData.length == 0) return 0
    if (planPriceData[0]?.BPPlanPricebook?.currencyIsoCode?.toUpperCase() === 'CDA' || planPriceData[0]?.BPPlanPricebook?.currencyIsoCode?.toUpperCase() === 'USD') {
        return Number(planPriceData[0]?.netPriceAmount)
    }
    return Number(planPriceData[0]?.grossPriceAmount)
}

export const getTotalAmount = ({ productDetails, selectedPlan }) => {
    const productAmount = getProductAmount(productDetails?.BPPricebookLineItems);
    const priceAmount = selectedPlan?.planAmount;

    return selectedPlan ? (productAmount + priceAmount) : productAmount;
}

export const getStripeProductPriceId = (productDetails) => (productDetails?.BPPricebookLineItems[0]?.stripePriceId || null)
export const getStripePlanPriceId = (selectedPlan) => (selectedPlan?.stripePriceId || null)

export const getMinProductAmount = (groupData) => {
    if (groupData?.currencyIsoCode === 'CDA' || groupData?.currencyIsoCode === 'USD') {
        return groupData.netPriceAmount
    }
    return groupData.grossPriceAmount
}

export const getAddOnAmount = (priceData) => {
    if (priceData?.pb?.curr === 'CDA' || priceData?.pb?.curr === 'USD') {
        return parseInt(priceData.netPriceAm);
    }
    return parseInt(priceData.grossPrice);
}
export const getAddOnAmount1 = (priceData) => {
    if (priceData?.pb?.curr === 'CDA' || priceData?.pb?.curr === 'USD') {
        return parseInt(priceData.netPriceAmount);
    }
    return parseInt(priceData.grossPriceAmount);
}


export const getI18n = (data) => {
    let result = data.filter(item => item.language_id == "en")
    if (result.length > 0) {
        return result[0]
    }
    return data[0];
}

export const getLangauge = (preferredLanguage) => {
    if (preferredLanguage.startsWith('en')) {
        return 'English';
    } else if (preferredLanguage.startsWith('de')) {
        return 'German';
    }
    else {
        return 'Unknown'; // Handle cases where the language code is not recognized
    }
}

export const setSessionId = (sessionId) => {
    cookies.set('SESSION_ID', sessionId, { path: '/', expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000) });
    return sessionId;
}

export const getSessionId = () => cookies.get('SESSION_ID', { path: '/' });

export const removeSessionId = () => {
    cookies.remove('SESSION_ID', { path: '/' });
}

export const getUserIpDetail = () => {
    return new Promise((resolve) => {
        axios
            .get("https://ipapi.co/json/")
            .then((response) => {
                if (response && response.data) {
                    resolve({ ...response.data, status: true });
                }
            })
            .catch((ex) => {
                resolve({ status: false, message: ex?.message, errorMessage: ex?.response?.data?.message, statusCode: ex?.response?.status });
            });
    });
};
