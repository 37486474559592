export const ALL_CARS_PAGE_TITLE = `Car Subscription: Suscribe to All Types of Cars in {country} | Zenova`;

export const ALL_CARS_PAGE_DESCRIPTION = `Join Zenova's Car Subscription— own your desired car: hassle-free. Enjoy flexibility to upgrade, cancel anytime, smart driving without long-term commitments.`;

export const GENERIC_CAR_PAGE_TITLE = `{carFullName} Car Subscription Starting at {startingPrice}/mo.`;

export const GENERIC_CAR_PAGE_DESCRIPTION = `{carFullName}: Enjoy comfort on the open road with {carFullName} car rental. Unbeatable deals starting from {startingPrice}/mo at Zenova. Subscribe now!`;

export const HOME_PAGE_TITLE = 'Zenova Car Subscription | Premium Car Rental for the Long Term';

export const HOME_PAGE_DESCRIPTION = `Zenova's car subscription. Drive your favorite car with a monthly fee covering insurance, maintenance. No downpayment or hassles—a smart driving experience.`;