import React, { useEffect } from "react";
import Header from "organisms/Header";
import { useUserStore } from "hooks/useUserStore";

const CatalogLayout = ({ children, ...props }) => {
  let currentUser, updateUser;
  if (props?.userDetails) {
    ({ user: currentUser, updateUser } = useUserStore());
    useEffect(() => {
      if (props?.userDetails) {
        updateUser({ ...currentUser, ...props?.userDetails });
      }
    }, [props])
  }

  
  return (
    <>
      <Header type="homeScreen" className="sticky top-0 z-50"
        isOpenLoginPopup={props?.showLoginPopup}
        email={props?.email}
      />
      {children}
    </>
  );
};

export default CatalogLayout;
