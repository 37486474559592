import * as HttpService from "./http.service";
import { getUserCountry } from "./locale.service";
import { DUMP_LOGS_TO_AWS_CLOUD_WATCH_URL } from "./url.service";

export const dumpLogsToAwsCloudWatch = (body) => {
    const country = getUserCountry();
    return HttpService.postWithOutAuth(DUMP_LOGS_TO_AWS_CLOUD_WATCH_URL, body, country);
};

export function logErrorToCLoudWatch(msg, url, lineNo, columnNo, error) {
    if (!error) return;

    const errorMessage = msg.toLowerCase();
    let errorType = "BrowserError: ";

    if (errorMessage.includes("script error")) {
        errorType = "Script Error: ";
    }
    const body = {
        logMessage: errorType + msg,
        logData: {
            url,
            error: { message: error.message, stack: error.stack },
            lineNo,
            columnNo,
            location: {
                host: window.location.host,
                hostname: window.location.hostname,
                href: window.location.href,
                origin: window.location.origin,
                pathname: window.location.pathname,
            },
        },
        type: 'error'
    };
    dumpLogsToAwsCloudWatch(body);
}

export function scrollToErrorMessage(errorRef) {
    errorRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
    });;
}