import Container from "molecules/Container";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { getUserCountry } from "services/locale.service";
import { useLocaleStore } from "hooks/useLocaleStore";

const Footer = ({ className, type = 'type2', height = 'h-[90px]', hideInmobile = true }) => {

  const router = useRouter();

  const countryL = (router.query.country?.toLowerCase() || getUserCountry().toLowerCase());
  const { isGermanIP } = useLocaleStore();

  const footerList = [
    {
      id: 1,
      title: `Zenova @${new Date().getFullYear()}`,
      className: ' md:block hidden',
      elementId: 'zenova-link',
      href: '/'
    },
    {
      id: 2,
      title: "Privacy & Legal",
      elementId: 'privacy-link',
      href: '/'
    },
    {
      id: 3,
      title: "Locations",
      elementId: 'location-link',
      href: '/'
    },
    {
      id: 4,
      title: "Contact us",
      elementId: 'contact-link',
      href: '/'
    },
  ];
  const footerList2 = [
    {
      id: 1,
      title: "FAQ",
      elementId: 'faq-link',
      href: `/en/${countryL}/faq`
    },
    {
      id: 2,
      title: "Terms & Conditions",
      elementId: 'term-and-condition-link',
      href: `/en/${countryL}/terms-of-service`
    },
    {
      id: 3,
      title: "Privacy Policy",
      elementId: 'privacy-policy-link',
      href: `/en/${countryL}/privacy`
    },
    ...(isGermanIP ? [
      {
        id: 4,
        type: 'special'
      },
      {
        id: 5,
        title: "Imprint",
        elementId: 'imprint-link',
        href: `/en/${countryL}/imprint`
      }
    ] : [])
    // {
    //   id: 4,
    //   title: "Rental agreement conditions",
    //   elementId: 'rental-agreement-conditions',
    //   href: `/en/${countryL}/rental-agreement`
    // }
  ]

  const socialLinks = [
    {
      id: 1,
      src: "/images/icons/insta.svg",
      onClick: () => { window.open('https://instagram.com/zenovaglobal', '_blank') }
    },
    {
      id: 2,
      src: "/images/icons/youtube.svg",
      onClick: () => { window.open('https://m.youtube.com/channel/UCo242l4098b-7g8z5Pso8bw', '_blank') }
    },
    {
      id: 3,
      src: "/images/icons/linkedin.svg",
      onClick: () => { window.open('https://www.linkedin.com/company/zenovaglobal/', '_blank') }
    },
    {
      id: 4,
      src: "/images/icons/x.svg",
      onClick: () => { window.open('https://twitter.com/Zenovaglobal', '_blank') }
    }
  ]

  return (
    <>
      {
        type === 'type1' && <div className={`${height} bg-neutral-90 flex items-center ${className || ''}`}>
          <ul className="flex mx-auto justify-between gap-x-10 md:gap-x-24">
            {footerList.map((item) => (
              <Link
                key={item.id}
                id={item.elementId}
                className={`text-neneutral-700 text-sm md:text-lg cursor-pointer font-light ${item.className || ''}`}
                href={item.href}
              >
                {item.title}
              </Link>
            ))}
          </ul>
        </div>
      }

      {
        type === 'type2' &&
        <div className={`pt-[32px] pb-[24px] bg-neutral-90 ${hideInmobile ? 'hidden md:flex' : 'flex flex-col'} justify-center ${className || ''}`}>
          <Container type="type0" className="flex flex-col md:flex-row justify-between items-start md:items-center gap-x-6">

            <ul className="flex flex-col md:flex-row gap-y-2 justify-between  gap-x-6 xl:gap-x-12">
              {footerList2.map((item) => {
                if (item.type == 'special') {
                  return (
                    <p
                      key={item.id}
                      id={item.elementId}
                      className={`text-neutral-900 text-sm xl:text-lg cursor-pointer font-light ${item.className || ''}`}
                      onClick={() => {
                        if (typeof UC_UI != 'undefined') {
                          UC_UI.showSecondLayer();
                        }
                      }}
                    >Privacy Settings</p>
                  )
                }

                return (
                  <li key={item.id}
                    id={item.elementId}
                    className={`text-neutral-900 text-sm xl:text-lg cursor-pointer font-light ${item.className || ''}`}>
                    <Link
                      href={item.href}
                    >
                      {item.title}
                    </Link>
                  </li>
                )
              })}
            </ul>
            <div className="flex gap-x-[50px] mt-6 md:mt-0">
              {
                socialLinks?.map(({ src, id, onClick }) => (
                  <span key={id} className="cursor-pointer relative w-[30px] h-[30px]" onClick={onClick} id={`social-link-${id}`}>
                    <Image key={id} src={src} alt='social links' layout="fill" />
                  </span>
                ))
              }
            </div>
          </Container>
        </div>
      }
    </>
  );
};

export default Footer;
