import React from 'react'
import Header from "organisms/Header";
import Footer from "organisms/Footer";

const NavbarFooterLayout = ({ children }) => {
    return (
        <div className='h-[100dvh] flex flex-col'>
            <Header className='flex-none' />
            {children}
            <Footer type='type2' className='flex-none' hideInmobile={false} />
        </div>
    )
}

export default NavbarFooterLayout