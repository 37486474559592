
const countries = [
    { value: 'DE', label: 'Germany' },
    { value: 'AE', label: 'United Arab Emirates' },
];


const localesOptions = [
    { value: 'en', label: 'English' }
  ]

const countryCurrency = {
    DE: "EURO",
    US: "USD",
    SA: "SAR",
    AE: "AED",
};

const isAvailableCountry = (country) => country && countries.find((c) => c.value === country.toUpperCase())

const localeData = [
    { locale: 'en', countryIsoCode: 'ae', label: 'UAE' },
    { locale: 'en', countryIsoCode: 'de', label: 'Germany' },
    { locale: 'de', countryIsoCode: 'de', label: 'Deutschland' }
  ];

export { countries, countryCurrency , localesOptions , isAvailableCountry, localeData };
