import React from "react";
import PropTypes from "prop-types";
const Container = ({ children, type = "type1", className, ...property }) => {
  const contentType = {
    type1: "type1",
    type2: "type2",
    type3: "type3",
    type4: "type4",
    type5: "type5",
    type6: "type6",
    type7: "type7",
  };
  const containerStyle = {
    _type:"w-full cd:max-w-[1311px] mx-auto",
    type0: "w-11/12 cd:max-w-[1311px] xl:max-w-[1130px] mx-auto",
    type1: "w-full md:max-w-[1480px] px-[14px] sm:px-[26px] md:px-5 mx-auto",
    type2: "w-full md:max-w-[1400px] px-[26px] md:px-5  mx-auto",
    type3: "w-full md:max-w-[1360px] px-[26px] md:px-5  mx-auto",
    type4: "w-full md:max-w-[760px] mx-auto px-4 md:px-6",
    type5: "w-full md:max-w-211.5 mx-auto",
    type6: "w-full md:max-w-48.5",
    type7: "w-full md:max-w-157",
    type8: "w-full md:max-w-130",
    type9: " w-full md:max-w-211.5 mx-auto",
  };
  return (
    <div
      type={contentType[type]}
      className={` ${containerStyle[type]} ${className || ""}`}
      {...property}
    >
      {children}
    </div>
  );
};
export default Container;
Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};