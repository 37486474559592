import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const setCouponInCookies = (coupon) => {
    cookies.set('SESSION_COUPON', coupon, { path: '/' });
    return coupon;
}

export const getCouponFromCookies = () => cookies.get('SESSION_COUPON', { path: '/' });

export const removeCouponFromCookies = () => cookies.remove('SESSION_COUPON', { path: '/' });