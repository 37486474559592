import React from 'react'
import Image from 'atoms/Image';
import Header from 'organisms/Header';
import Heading from 'atoms/Heading';

const Loader = ({ showHeader = true }) => {
    return (
        <>
            { showHeader && <Header /> }
            <div className='bg-primary-400 h-[calc(100dvh-70px)] md:h-[calc(100dvh-73px)] px-5 pt-[30px] md:pt-20 xl:pt-[142px] md:px-[100px] xl:px-[212px] relative overflow-hidden'>
                <Heading type='h1' fontSize='text-[32px] md:text-[50px]' className='text-black font-medium'>Setting up your dashboard</Heading>
                <Heading type='h6' fontSize='text-lg md:text-xl' className='text-neutral-900 font-medium md:mt-6'>This should take just a few seconds...</Heading>
                <Image imgSrc='/images/curve-3.svg' alt='curve' width={356} height={390} className='absolute left-0 bottom-0 w-[198px] h-[218px] md:w-[356px] md:h-[390px]' />
                <Image imgSrc='/images/curve-2.svg' alt='curve' width={1224} height={602} className='absolute right-0 top-[25%] w-[604px] h-[298px] md:w-auto md:h-[602px]' />
            </div>
        </>
    )
}

export default Loader