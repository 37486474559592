import "../styles/globals.css";
import { FlagsmithProvider } from "flagsmith/react";
import flagsmith from "flagsmith/isomorphic";
import getLayout from "../layouts/LayoutConfiguration";
import { useRouter } from "next/router";
import axios from "axios";
import { getAuth, removeAuth, setAuth, setTenantId } from 'services/identity.service';
import { useEffect, useRef, useState } from "react";
import { appWithTranslation } from 'next-i18next';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Head from 'next/head';
import { HOME_PAGE_TITLE, HOME_PAGE_DESCRIPTION } from "services/constants.service";
import { addProductJsonLd } from "services/utilities.service";
import { Toaster } from 'sonner';
import LoaderPage from "templates/Loader";
import ErrorHandlerWrapper from "components/ErrorHandlerWrapper";
import { logErrorToCLoudWatch } from "services/error-log-utilities.service";
import NextImage from 'next/image'
import WhatsappChat from "molecules/WhatsappChat";
import { useMobileScreen } from "hooks/useMobileScreen";
import { useLocaleStore } from "hooks/useLocaleStore";

function MyApp({ Component, pageProps, flagsmithState, subDomain }) {
    setTenantId(subDomain);
    const router = useRouter();
    const layout = getLayout(router.pathname);
    const wspRef = useRef();
    const [isExpanded, setIsExpanded] = useState();
    const { isMobileScreen } = useMobileScreen();
    const { country, setIsGermanIP } = useLocaleStore();

    const isGermanIp = pageProps?.ipData?.country_code?.toLowerCase() == 'de';

    const firebaseConfig = {
        apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
        authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
        storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
        measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID
    };

    async function getRefreshToken({ status }) {
        if (status === 401) {
            const auth = getAuth();
            if (auth) {
                try {
                    const axiosRes = await axios.post(`${process.env.NEXT_PUBLIC_API_URL}/refresh-tokens`, { refreshToken: auth.refreshToken, userId: auth.id });
                    setAuth(axiosRes.data.entity);

                    localStorage.setItem('user-details', JSON.stringify(axiosRes.data.entity))
                    const currentUrl = router.asPath;
                    window.open(currentUrl, '_self');
                } catch (err) {
                    console.error('Error while getting new token', err);
                    removeAuth();
                    window.open('/', '_self');
                }
            } else {
                removeAuth();
                window.open('/', '_self');
            }
        }
    }

    const registerServiceWorker = async () => {
        if ('serviceWorker' in navigator) {
            try {
                const registration = await navigator.serviceWorker.register('/service-worker.js');
                console.log('Service Worker registered with scope:', registration.scope);
            } catch (error) {
                console.error('Service Worker registration failed:', error);
            }
        }
    };

    function toggleExpandWhatsappOnDesktop() {
        if(pageProps?.isWspExpanded) {
            setIsExpanded(window.innerWidth > 768);
            setTimeout(()=>{
                setIsExpanded(false);
            }, 3000)
        }   
    }

    useEffect(() => {
        if (pageProps) {
            getRefreshToken({ status: pageProps.statusCode })
        }
    }, [pageProps]);

    useEffect(() => {        
        AOS.init({
            duration: 800,
        });

        setIsGermanIP(isGermanIp);
    }, []);

    useEffect(() => {
        // Initialize Firebase
        if (process.env.NEXT_PUBLIC_CLEVERTAP_ACCOUNT_ID) {
            const app = initializeApp(firebaseConfig);
            getAnalytics(app);
            registerServiceWorker();
        }
        toggleExpandWhatsappOnDesktop();

        window.onerror = logErrorToCLoudWatch;
    }, []);

    return (
        <FlagsmithProvider flagsmith={flagsmith} serverState={flagsmithState}>
            <Head>
                {
                    process.env.NEXT_PUBLIC_USER_CENTRIC_SETTING_ID && country && isGermanIp && process.env.NEXT_PUBLIC_ENV == 'qa' &&
                    <script id="usercentrics-cmp" src="https://app.usercentrics.eu/browser-ui/latest/loader.js" data-version="preview" data-settings-id={process.env.NEXT_PUBLIC_USER_CENTRIC_SETTING_ID} async></script>
                }

                {
                    process.env.NEXT_PUBLIC_USER_CENTRIC_SETTING_ID && country && isGermanIp && process.env.NEXT_PUBLIC_ENV == 'production' &&
                    <script id="usercentrics-cmp" src="https://app.usercentrics.eu/browser-ui/latest/loader.js" data-settings-id={process.env.NEXT_PUBLIC_USER_CENTRIC_SETTING_ID} async></script>
                }

                <meta name="robots" content="index,follow" />
                <meta
                    name="viewport"
                    content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=0"
                />
                <meta charSet="utf-8" />
                <title>{(pageProps?.title || HOME_PAGE_TITLE)}</title>
                <meta property="og:title" content={(pageProps?.title || HOME_PAGE_TITLE)} />
                <meta name="twitter:title" content={(pageProps?.title || HOME_PAGE_TITLE)} />
                {
                    <>
                        <meta
                            name="description"
                            content={pageProps?.description || HOME_PAGE_DESCRIPTION}
                        />
                        <meta
                            property="og:description"
                            content={pageProps?.description || HOME_PAGE_DESCRIPTION}
                        />
                        <meta
                            name="twitter:description"
                            content={pageProps?.description || HOME_PAGE_DESCRIPTION}
                        />
                    </>
                }
                {
                    pageProps?.keywords &&
                    <>
                        <meta
                            name="keywords"
                            content={pageProps.keywords}
                        />
                    </>
                }
                {
                    pageProps?.seoImageUrl &&
                    <>
                        <meta
                            property="og:image"
                            content={pageProps?.seoImageUrl}
                        />
                        <meta
                            name="twitter:image"
                            content={pageProps?.seoImageUrl}
                        />
                    </>
                }
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en" />
                <meta property="og:site_name" content="Zenova" />
                <meta property="og:url" content="https://www.zenova.com" />
                <meta name="twitter:card" content="summary" />

                {
                    process.env.NEXT_PUBLIC_ENV === 'production' &&
                    <meta name="google-site-verification" content={process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION} />
                }

                {
                    (process.env.NODE_ENV == 'production' && pageProps?.showCanonicalLink) ?
                        <link rel="canonical" href={pageProps.pageUrl} /> : null
                }

                {
                    (pageProps?.baseProductData) ?
                        <script
                            type="application/ld+json"
                            dangerouslySetInnerHTML={addProductJsonLd(pageProps?.baseProductData)}
                            key="product-jsonld"
                        /> : null
                }
            </Head>
            <ErrorHandlerWrapper>
                {layout({
                    ...pageProps, children: (Array.isArray(pageProps?.apiErrors) && pageProps?.apiErrors.length) ?
                        <LoaderPage showHeader={false} /> :
                        <>
                            <ErrorHandlerWrapper>
                                <Component {...pageProps} />
                            </ErrorHandlerWrapper>
                            <Toaster
                                position="bottom-left"
                                toastOptions={{
                                    style: {
                                        fontFamily: 'fieldwork',
                                        fontSize: '16px',
                                    },
                                }}
                            />
                        </>
                })}
                {
                    <>
                        {/* <div className="cursor-pointer fixed bottom-0 left-0 z-10">
                            <a ref={wspRef} target="_blank" href={`https://api.whatsapp.com/send/?phone=${router.query?.country?.toLowerCase() == 'ae' ? '4915256402707' : '491721470700'}&text=Hi`}>
                            </a>
                        </div>
                        <WhatsappChat
                            onClick={() => {
                                wspRef.current?.click();
                            }}
                            onMouseEnter={() => {
                                if (!isMobileScreen) {
                                    setIsExpanded(true);
                                }
                            }}
                            onMouseLeave={() => {
                                setIsExpanded(false);
                            }}
                            isExpanded={isExpanded}
                        /> */}
                    </>
                }
            </ErrorHandlerWrapper>
        </FlagsmithProvider>
    );
}

MyApp.getInitialProps = async (appContext) => {
    
    const subDomain = getSubDomain(appContext.ctx.req);

    await flagsmith.init({
        environmentID: process.env.NEXT_PUBLIC_FLAGSMITH_KEY,
    });
    return { flagsmithState: flagsmith.getState(), subDomain};
};

const getSubDomain = (req) => {

    const ROOT_DOMAIN =
    process.env.NODE_ENV === "production" ? "zenova.com" : "localhost:4200";
    console.log("request", req.headers);
    const host = req.headers["host"];
    let subDomain = ROOT_DOMAIN;
    if (host.endsWith(`.${ROOT_DOMAIN}`)) {
        subDomain = host.replace(`.${ROOT_DOMAIN}`, "");
    }
    return subDomain;
}

export default appWithTranslation(MyApp);
