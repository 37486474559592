import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { dumpLogsToAwsCloudWatch } from 'services/error-log-utilities.service';
import NotFound from 'templates/NotFound';

export default function ErrorHandlerWrapper({ children }) {
    function errorBoundaryHandler(error) {
        const body = {
            logMessage: 'BrowserError: Error occured in the component',
            logData: { errorStack: error.stack, message: error.message }
        }
        dumpLogsToAwsCloudWatch(body);
    }

    return (
        <ErrorBoundary
            FallbackComponent={() => <NotFound showHeader={false} />}
            onError={errorBoundaryHandler}
        >
            {children}
        </ErrorBoundary>
    )
}
