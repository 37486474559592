import { yupResolver } from "@hookform/resolvers/yup";
import Button from "atoms/Button";
import Heading from "atoms/Heading";
import InputBox from "atoms/InputBox";
import Label from "atoms/Label";
import Modal from "molecules/Modal";
import Image from "next/image";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getCountryCallingCode } from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import "react-phone-number-input/style.css";
import { enWithCode } from "services/helper-function.service";
import * as yup from "yup";
import { getUserCountry } from 'services/locale.service';

const SignUp = ({
  showModal,
  closeModal = () => { },
  signUp,
  defaultValues = {},
  type = "default",
}) => {
  const schema = yup
    .object({
      fullName: yup.string().test('error', 'Full Name should be only characters and max upto 50!', (val) => /^[a-zA-Z\s]{1,50}$/.test(val)).required('Full Name is required!'),
      mobile: yup.string().required('Mobile is required!').test((value) => (value.length <= 18 && value.length > 7))
    })
    .required();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });
  const [changeBorderColor, setChangeBorderColor] = useState(false);
  const country = getUserCountry();


  useEffect(() => {
    if (defaultValues) {
      setValue('fullName', defaultValues.fullName);
      setValue('mobile', defaultValues.mobile);
    }
  }, [defaultValues]);


  return (
    <Modal
      className=""
      showModal={showModal}
      overLayClass="fixed left-0 right-0 bottom-0 z-[60]"
      handleClickOutside={closeModal}
      classForCard="max-w-[561px] w-full py-[44px] md:py-[60px] px-[27px] md:px-[60px] rounded-t-[30px] md:rounded-lg absolute bottom-0 md:static h-[80vh] md:h-auto"
      closeModel={closeModal}
      cardInCenter={true}
      cardBgColor="bg-white"
      bgColor="bg-black bg-opacity-75"
      showCloseIcon={false}
      height="h-screen md:h-[calc(100vh-73px)]"
    >
      {type === "default" && (
        <>
          <div className="flex justify-between gap-x-20">
            <Heading
              type="h3"
              fontSize="text-xl md:text-[30px]"
              className="text-black font-semibold"
            >
              Create an account
            </Heading>
            <span className="cursor-pointer mt-2">
              <Image
                src="/images/icons/close-black.svg"
                alt="close  black"
                width={18}
                height={18}
                onClick={closeModal}
              />
            </span>
          </div>
          <InputBox
            id="fullName"
            name="fullName"
            dbName="fullName"
            placeholder="Full Name"
            variant="Default"
            labelFor="fullName"
            register={register}
            type="text"
            isError={false}
            errorMessage={errors["fullName"]?.message}
            labelText="Full Name"
            parentClass="my-5"
          />

          <div>
            <Label
              className="text-opacity-40 text-xs"
              labelFor="phoneNumber"
              textColor="text-neutral-800"
              variant="large"
            >
              Mobile
            </Label>
            <div className="flex-col gap-5 mt-[13px]">
              <PhoneInputWithCountry
                id="mobile"
                name="mobile"
                control={control}
                labels={enWithCode}
                rules={{
                  required: true,
                  validate: (value) => isValidPhoneNumber(value),
                }}
                onCountryChange={(val) => {
                  const mb = getValues('mobile');
                  
                  if (val && mb) {
                    const { nationalNumber } = (mb && parsePhoneNumber(mb)) || { nationalNumber: '' };
                    const countryCode = getCountryCallingCode(val);
                    setTimeout(() => {
                      setValue('mobile', `+${countryCode}${nationalNumber}`)
                    }, 50)
                  }
                }}
                onFocus={() => setChangeBorderColor(true)}
                onBlur={() => setChangeBorderColor(false)}
                international={true}
                defaultCountry={country}
                countryOptionsOrder={["DE", "US", "..."]}
                className={`relative  ${changeBorderColor ? "border-black" : "border-secondary-350"
                  } border !focus:outline-none rounded-[5px] px-2.5 py-[15px] text-black text-sm w-full placeholder-secondary-250 focus:placeholder-opacity-0`}
              />
              {errors["mobile"] && (
                <p className="select-none text-error-100 pl-0.5 text-sm pt-2 mt-4 font-normal leading-[21px]">
                  {errors["mobile"]?.message}
                </p>
              )}
            </div>

            <Button
              id="signUp-confirm"
              className="py-2.5 px-[50px] mt-6 w-full md:w-auto"
              onClick={handleSubmit(signUp)}
              fontSize="text-xl md:text-lg"
              style="primary"
              btnText="Confirm"
              rounded="rounded-lg md:rounded-br-[20px] md:rounded-none"
            />
          </div>
        </>
      )}
      {type === "small" && (
        <div className="flex flex-col gap-8">
          <Heading
            type="h1"
            className="font-semibold text-center text-[32px]"
          >
            Confirm your Personal Details
          </Heading>

          <InputBox
            id="fullName"
            labelText="Full Name"
            isLabel={true}
            labelFor="fullName"
            placeholder="Enter Full Name"
            type="text"
            register={register}
            dbName={"fullName"}
            isError={errors.fullName}
            errorMessage={errors.fullName?.message}
            variant="Default"
            labelClass="text-base font-semibold"
            className="text-base bg-white placeholder:text-base"
          />
          <div>
            <Label
              labelFor="phoneNumber"
              textColor="text-black"
              fontWeight="font-semibold text-base"
            >
              Phone Number
            </Label>
            <div className="flex items-center gap-5 mt-2">
              <PhoneInputWithCountry
                id="phoneInputWithCountrySelect"
                name="mobile"
                control={control}
                labels={enWithCode}
                rules={{
                  required: true,
                  validate: (value) => isValidPhoneNumber(value),
                }}
                international={true}
                defaultCountry="IN"
                countryOptionsOrder={["DE", "US", "..."]}
              />
              {errors["phoneInputWithCountrySelect"] && (
                <p className="error-message">Invalid Mobile Number</p>
              )}
            </div>
          </div>
          <Button
            id="signUp-confirm"
            style="secondary"
            btnText="VERIFY"
            fontSize="text-sm"
            className="w-[200px] mx-auto font-semibold py-4 mt-4"
            onClick={handleSubmit(signUp)}
          />
        </div>
      )}
    </Modal>
  );
};

export default SignUp;
