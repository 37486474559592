import Text from 'atoms/Text'
import Image from 'next/image'
import React from 'react'

function WhatsappChat({ onClick, onMouseEnter, onMouseLeave, isExpanded }) {
  return (
    <div
      className={`fixed z-[100] ${!isExpanded ? 'w-[60px]' : 'w-full'} max-w-[305px] h-[60px] group transition-all duration-500 cursor-pointer bottom-[110px] md:bottom-10 left-4 md:left-11 bg-secondary-500 py-3 px-3.5 flex items-center rounded-full rounded-tr-none`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className='flex-none flex items-center justify-center'>
        <Image
          src='/images/whatsapp.png'
          width={35}
          height={36}
        />
      </div>
      <Text className={`${!isExpanded ? 'w-0 opacity-0' : 'text-white opacity-100 w-auto transition-all duration-500 whitespace-nowrap transform -translate-x-50 px-3'}`}>Facing an issue?  Chat with us</Text>
    </div>
  )
}

export default WhatsappChat;