import Button from "atoms/Button";
import Heading from "atoms/Heading";
import {
  countries,
  localesOptions,
} from "constants/country-locale-list";
import Dropdown2 from "molecules/Dropdown2";
import Modal from "molecules/Modal";
import NextImage from "next/image";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import { updateCustomer } from "services/customer.service";
import { getUserCountry, getUserLocale, setUserCountry, setUserLocale } from "services/locale.service";

const RegionalModal = ({
  showModal = false,
  handleClickOutside = () => { },
  closeModel = () => { },
  selectedCountry,
  setSelectedCountry,
  selectedLocale,
  setSelectedLocale,
  userData,
}) => {
  const router = useRouter();

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };

  const handleLocaleChange = (selectedOption) => {
    setSelectedLocale(selectedOption);
  };

  const updatePreferredLanguage = async () => {
    try {
      if (!userData?.id) return;

      await updateCustomer(
        {
          preferredLanguage: selectedLocale.value,
        },
        userData?.id
      );
    } catch (error) {
      console.log(
        "🚀 ~ file: RegionalModal.js:48 ~ updatePreferredLanguage ~ error:",
        error
      );
    }
  };

  const addLocale = async () => {
    const redirectToAllCars = ['/basic-details', '/all-cars', '/subscription-dashboard', '/payments', '/delivery-details']

    await updatePreferredLanguage();
    const country = selectedCountry.value;
    const lang = selectedLocale.value;
    const currentPath = router.asPath;

    const updatedAsPath = currentPath.slice(3);
    const countryL = country.toLowerCase();

    const REDIRECT_URL = redirectToAllCars.find(url => currentPath.includes(url)) ? `/${lang}/${countryL}/all-cars` : `/${lang}/${countryL}${updatedAsPath}`;

    setUserLocale(lang);
    setUserCountry(country);
    window.open(REDIRECT_URL, '_self');
    closeModel();
  };

  const handleCloseModal = () => {
    handleCountryChange(countries.find(country => country.value === getUserCountry()))
    handleLocaleChange(localesOptions.find(locale => locale.value === getUserLocale()))
    closeModel();
  }

  return (
    <Modal
      className=""
      showModal={showModal}
      overLayClass="fixed left-0 right-0 bottom-0 z-[100]"
      handleClickOutside={handleCloseModal}
      classForCard="md:max-w-[561px] w-full py-[44px] md:py-[60px] px-[27px] md:px-[60px] rounded-t-[30px] md:rounded-lg absolute bottom-0 md:static h-[calc(100dvh-74px)] md:h-auto"
      closeModel={handleCloseModal}
      cardInCenter={true}
      cardBgColor="bg-white"
      bgColor="bg-black bg-opacity-75"
      showCloseIcon={false}
      height="h-[calc(100dvh-70px)] md:h-[calc(100dvh-73px)]"
    >
      <div className="flex justify-between items-center mb-[54px]">
        <Heading
          type="h3"
          fontSize="text-xl md:text-[30px]"
          className="text-black font-semibold"
        >
          Regional settings
        </Heading>
        <span className="cursor-pointer" onClick={handleCloseModal}>
          <NextImage
            src="/images/icons/close-black.svg"
            alt="close  black"
            width={18}
            height={18}
          />
        </span>
      </div>

      <Dropdown2
        id="country"
        label="Country/Region"
        labelFor="country"
        name="country"
        labelColor="text-neutral-700"
        classForLabel="text-lg text-left"
        options={countries}
        handleChange={handleCountryChange}
        selectedValue={selectedCountry}
        classForContainer="text-left"
      />
      <Dropdown2
        id="locale"
        name="locale"
        label="Language"
        labelFor="locale"
        labelColor="text-neutral-700"
        classForLabel="text-lg text-left"
        options={localesOptions}
        selectedValue={selectedLocale}
        handleChange={handleLocaleChange}
        classForContainer="my-[43px] text-left"
      />
      <div className="flex justify-center mt-4">

        <Button
          id="done_button"
          className="py-2.5 px-[50px] mt-6 w-full md:w-auto"
          onClick={addLocale}
          fontSize="text-xl md:text-lg"
          style="primary"
          btnText="Accept"
          rounded="rounded-lg md:rounded-br-[20px] md:rounded-none"
        />
      </div>

    </Modal>
  );
};

RegionalModal.propTypes = {
  showModal: PropTypes.bool,
  handleClickOutside: PropTypes.func,
  closeModel: PropTypes.func,
};

export default RegionalModal;
