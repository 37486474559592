import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const getAuth = () => {
    const auth = cookies.get('ZENOVA_AUTH');
    return auth
}

export const setAuth = (authObject) => {
    cookies.set('ZENOVA_AUTH', JSON.stringify({
        id: authObject.id,
        accessToken: authObject.authTokens.id_token,
        refreshToken: authObject.authTokens.refresh_token
    }), { path: '/' });

    return authObject;
}

export const removeAuth = () => {
    cookies.remove('ZENOVA_AUTH', { path: '/' });
    if (typeof window !== 'undefined') {
        window.location.reload();
        localStorage.removeItem('user-details');
    }
    return;
}

export const isAuthenticated = (user) => {
    return user && user.accessToken;
}

export const isUnauthorizedRequest = (auth, token) => {
    return !auth || !isAuthenticated(JSON.parse(auth), token);
};

export const getServerSideUserAuth = (cookieAuth) => {
    if(!(!cookieAuth || !isAuthenticated(JSON.parse(cookieAuth)))) {
        return JSON.parse(cookieAuth);
    }

    return null;
}

export const setTenantId = (tenantId) => {
    cookies.set('tenantId', tenantId, {
        path: '/'
    });
}

export const getTenantId = () => {
    let c = cookies.get('tenantId');
    console.log("c", c);
    return c;
}