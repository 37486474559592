import AccountLayout from "layouts/AccountLayout";
import CatalogLayout from "layouts/CatalogLayout";
import NavbarFooterLayout from "layouts/NavbarFooterLayout";
import EmptyLayout from "./EmptyLayout";

const LayoutConfig = [
    {
        path: "/all-cars",
        layout: EmptyLayout,
    },
    {
        path: "/confirmation",
        layout: NavbarFooterLayout,
    },
    {
        path: "/basic-details",
        layout: EmptyLayout,
    },
    {
        path: "/subscription-dashboard",
        layout: CatalogLayout,
    },
    {
        path: "/account-dashboard",
        layout: AccountLayout,
    },
    {
        path: "/",
        layout: EmptyLayout,
    },
];

const getLayout = (path) => {
    const configuration = LayoutConfig.find((config) =>
        path.includes(config.path)
    );
    if (configuration) return configuration.layout;
    return EmptyLayout;
};

export default getLayout;
