import React from "react";
import Select, { components } from "react-select";
import IcomoonIcon from "atoms/IcomoonIcon";

const CaretDownIcon = () => {
  return (
    <IcomoonIcon
      icon="angle-left"
      color="#000"
      className="w-[13px] h-[13px] transform -rotate-90"
    />
  );
};

let isOpen = false;
const CaretUpIcon = () => {
  return (
    <IcomoonIcon
      icon="angle-left"
      color="#000"
      className="w-[13px] h-[13px] transform rotate-90"
    />
  );
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      {isOpen ? <CaretUpIcon /> : <CaretDownIcon />}
    </components.DropdownIndicator>
  );
};
const Dropdown2 = ({
  classForContainer = "",
  label,
  labelFor,
  id,
  placeholder,
  isDisabled = false,
  errors,
  options,
  selectedValue,
  name,
  defaultValue,
  labelColor = "text-secondary-500",
  handleChange = () => {},
  classForLabel,
}) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "54px",
      height: "54px",
      border: state.isDisabled
        ? "1px solid #d5d9dc66"
        : state.isFocused
        ? "1px solid #000"
        : errors
        ? "1px solid #FB4B4B"
        : "1px solid #000",
      boxShadow: "none",
      borderRadius: "5px",
      cursor: "pointer",
      paddingLeft: "20px",
      paddingRight: "20px",
      "&:hover": {
        border: "1px solid #000",
      },
      backgroundColor: state.isDisabled ? "#ffffffcc" : "#FFF",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0px",
      fontSize: "14px",
      textAlign:'left'
    }),

    singleValue: (provided, state) => ({
      ...provided,
      color: "#000",
    }),

    menu: (provided, state) => ({
      ...provided,
      borderWidth: "1px",
      borderColor: "#A4A4A4",
      borderRadius: "5px",
      boxShadow: "none",
    }),
    option: (provided) => ({
      ...provided,
      color: "#1F1F37",
      backgroundColor: "#FFF",
      cursor: "pointer",
      borderRadius: "0px",
      fontSize: "14px",
      paddingLeft: "26px",
      paddingRight: "26px",
      fontWeight: "400",
      "&:hover": {
        backgroundColor: "#D9D9D9",
      },
    }),
    indicatorsContainer: () => ({
      display: "flex",
      alignItems: "center",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
  };
  return (
    <div className={classForContainer}>
      {label && (
        <label
          htmlFor={labelFor}
          className={`mb-2 block ${classForLabel} ${labelColor}`}
        >
          {label}
        </label>
      )}

      <Select
        id={id}
        name={name}
        placeholder={placeholder}
        value={selectedValue}
        defaultValue={defaultValue}
        options={options}
        isDisabled={isDisabled}
        isSearchable={true}
        styles={customStyles}
        onChange={handleChange}
        onMenuOpen={() => (isOpen = true)}
        onMenuClose={() => (isOpen = false)}
        components={{ DropdownIndicator }}
      />
      {errors && (
        <div className="text-error-100 text-sm select-none">
          {errors[name]?.errorMessage}
        </div>
      )}
    </div>
  );
};

export default Dropdown2;
