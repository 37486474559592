import { yupResolver } from "@hookform/resolvers/yup";
import Button from "atoms/Button";
import Heading from "atoms/Heading";
import InputBox from "atoms/InputBox";
import Label from "atoms/Label";
import LoginButton from "atoms/LoginButton";
import Text from "atoms/Text";
import Modal from "molecules/Modal";
import Image from "next/image";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useRouter } from 'next/router';
import { formatTCountdownTimer } from "services/helper-function.service";
import OTPInput from 'react-otp-input';
import { loginWithEmail } from "services/auth.service";
import { getUserLocale } from "services/locale.service";
import { useUserStore } from "hooks/useUserStore";
import { emailRegex } from "services/utilities.service";

const LoginModal = ({
  showLoginModal = false,
  handleCloseModal = () => { },
  handleAppleLogin = () => { },
  handleGoogleLogin = () => { },
  handleSendOtp = () => { },
  handleResendOtp = () => { },
  handleConfirmOtp = () => { },
  setShowLoginWithOtpPopup=()=>{},
  setErrorMessage=()=>{},
  showOtp = false,
  isStickyPopup,
  email = null,
  infoMessage,
  errorMessage,
}) => {
  const { user: currentUser, updateUser } = useUserStore();
  const router = useRouter();
  const isDriverInvitaion = router.query.type === 'driver-invitation'
  const [countDown, setCountDown] = useState(60);
  const [otp, setOtp] = useState('');
  const schema = yup
    .object({
      email: yup.string().email('Wrong email').test('error', 'Wrong email', (val) => emailRegex.test(val)).required('Email is required!'),
    })
    .required();


  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      email: email,
    },
  });


  const startCountDownTimer = () => {
    setCountDown(60);
    const interval = setInterval(() => {
      setCountDown((prev) => {
        if (prev > 1) {
          return prev - 1;
        } else {
          clearInterval(interval);
          return 0;
        }
      });
    }, 1000);
  };

  useEffect(() => {
    if (showOtp) {
      startCountDownTimer();
      setOtp('');
      setErrorMessage(null);
    }
  }, [showOtp]);

  useEffect(() => {
    if (email) {
      setValue('email', email);
    }
  }, [email]);



  useEffect(() => {
    const otpSentForAdditionalDriverLogin = localStorage.getItem('sent-otp');
    if (showOtp && !otpSentForAdditionalDriverLogin) {
      loginWithEmail({ email, preferredLanguage: getUserLocale() }).then((res) => {
        if (res.status) {
          updateUser({ ...currentUser, email, session: res.entity.session });
          localStorage.setItem('sent-otp', true);
        }
      })
    }

    return () => {
      localStorage.removeItem('sent-otp');
    }

  }, [])


  return (
    <Modal
      className=""
      showModal={showLoginModal || showOtp}
      overLayClass="fixed left-0 right-0 bottom-0 z-[200]"
      handleClickOutside={handleCloseModal}
      classForCard={`md:max-w-[561px] w-full py-[44px] cd:pt-10 pb-[60px] ld:pt-[50px] ld:pb-[90px] px-[27px] md:px-[60px] rounded-t-[30px] md:rounded-lg absolute bottom-0 md:static h-[calc(100dvh-78px)] md:h-auto overflow-y-auto`}
      closeModel={handleCloseModal}
      cardInCenter={true}
      cardBgColor="bg-white"
      bgColor="bg-black bg-opacity-75"
      showCloseIcon={false}
      height={`${router.asPath.includes('#login') && router.asPath.includes('/basic-details') ? 'h-[100dvh]' : 'h-[calc(100dvh-70px)] md:h-[calc(100dvh-73px)]'} `}
    >
      <div className="flex justify-between items-center">
        <Heading
          type="h3"
          fontSize="text-xl md:text-[30px]"
          className="text-black font-medium"
        >
          Login/Signup {isDriverInvitaion && <span>to accept invitation</span>}
        </Heading>
        {
          !isStickyPopup &&
          <span className="cursor-pointer">
            <Image
              src="/images/icons/close-black.svg"
              alt="close  black"
              width={18}
              height={18}
              onClick={handleCloseModal}
            />
          </span>
        }
      </div>

      {!showOtp ? (
        <>
          <LoginButton
            bgColor="bg-[#1F262F]"
            btnText="Continue with Google"
            textColor="text-white"
            imgSrc="/images/icons/google-coloured.svg"
            className="mb-[11px] md:mb-[14px] mt-[41px] large:mt-[34px] ld:mt-[90px] large:mb-5"
            onClick={handleGoogleLogin}
            id='continue-with-google'
          />

          <LoginButton
            bgColor="bg-[#E1E3E5]"
            btnText="Continue with Apple"
            textColor="text-neutral-900"
            imgSrc="/images/icons/black-apple-icon.svg"
            id='continue-with-apple'
            onClick={handleAppleLogin}
          />

          <div className="bg-black bg-opacity-20 h-[1px] relative select-none my-[50px] large:mt-[100px] large:mb-[72px]">
            <Label
              labelFor="or"
              variant="large"
              textColor="text-neutral-700"
              className="px-[18px] bg-white absolute -top-3 left-1/2 -translate-x-1/2"
            >
              OR
            </Label>
          </div>
          <InputBox
            id="email"
            name="email"
            dbName="email"
            labelFor="email"
            placeholder="Enter Email address"
            register={register}
            type="email"
            variant="Default"
            isError={!!errors?.email?.message}
            errorMessage={errors?.email?.message}
            labelText="What’s your email address?"
            labelFontSize='text-lg'
            labelClass='!mb-[18px]'
            labelColor='text-neutral-700'
          />

          <Button
            id="send-otp"
            disabled={!watch("email") || errors.email}
            className="py-2.5 px-[50px] mt-[30px] w-full md:w-auto"
            onClick={handleSubmit(handleSendOtp)}
            fontSize="text-xl md:text-lg"
            style="primary"
            btnText="Send OTP"
            rounded="rounded-lg md:rounded-br-[20px] md:rounded-none"
          />
        </>
      ) : (
        <>
          <div className="flex justify-between gap-x-2 mt-3">
            <Text variant="bodyLarge" textColor="text-neutral-700" fontSize="text-lg" className='w-[80%] break-words'>OTP sent to {getValues('email')}</Text>
            <Text variant="body" textColor="text-primary-500" fontSize="text-base" className='hover:underline cursor-pointer w-[15%]' onClick={()=>setShowLoginWithOtpPopup(false)}>Change</Text>
          </div>

              <OTPInput
                onChange={(value) => {
                  setOtp(value)
                }}
                numInputs={6}
                inputType="number"
                placeholder=""
                shouldAutoFocus={true}
                value={otp}
                renderSeparator={<span> </span>}
                renderInput={(props) => <input type="number" {...props} />}
                className="w-full"
                containerStyle="flex gap-x-1 justify-between"
                inputStyle={`!w-10 !h-10 mobile:!w-[50px] mobile:h-10 sm:!w-[66px] sm:!h-[46px] bg-white outline-none font-light !rounded text-secondary-500 text-lg mobile:text-xl mt-10 mobile:mt-[83px] md:mt-[54px] mb-3
                                ${errorMessage
                    ? 'border border-error-200'
                    : 'border border-secondary-550'
                  }
                `}
              />
          <div className="flex justify-between mb-[30px]">
            {errorMessage ? <Text variant="bodyLarge" textColor="text-error-200">
              Invalid OTP
            </Text> : <Text variant="bodyLarge" textColor="text-[#B9B9B9]" className="">
              Time Remaining {formatTCountdownTimer(countDown)}
            </Text>}
            <Text
              id="otp-resend-button"
              variant="bodyLarge"
              textColor="text-primary-500"
              className="cursor-pointer hover:underline"
              onClick={() => {
                handleResendOtp();
                startCountDownTimer();
              }}
            >
              Resend OTP
            </Text>

          </div>
          <Button
            id="otp-send-next-button"
            className="py-2.5 px-10 w-full md:w-auto"
            onClick={()=>handleConfirmOtp(otp)}
            fontSize="text-xl md:text-lg"
            style="primary"
            btnText="Next"
            rounded="rounded-lg md:rounded-br-[20px] md:rounded-none"
            disabled={!(otp?.length === 6) || errorMessage}
          />

          {infoMessage && (
            <Text
              variant="bodyLarge"
              textColor="text-neutral-700"
              className="text-center mt-14"
            >
              {infoMessage}
            </Text>
          )}

        </>
      )}
    </Modal>
  );
};

export default LoginModal;
