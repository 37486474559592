import * as HttpService from "./http.service";
import {
    CREATE_CUSTOMER_URL, GET_CUSTOMER_BY_ID_URL, UPDATE_CUSTOMER_URL, CREATE_CUSTOMER_ADDRESS_URL,
    SEND_OTP_WITH_MOBILE_URL, VERIFY_OTP_WITH_MOBILE_URL,
    GET_DEFAULT_CUSTOMER_ADDRESSES_URL, UPDATE_CUSTOMER_ADDRESSES,
    GET_DRIVING_LICENSE_DETAILS_URL, UPDATE_CUSTOMER_DATE_AND_ADDRESSES, UPDATE_DRIVING_LICENSE_DETAILS_URL, GET_HOME_BILLING_OR_DELIVERY_ADDRESSES_URL, GET_CUSTOMER_CONTRACT_CONTACT_URL, GET_LATEST_CUSTOMER_ADDRESSE_MOBILE_URL, UPDATE_COLLECTION_ADDRESS_AND_DATE_URL,
    CREATE_CUSTOMER_SESSION_URL, GET_CUSTOMER_SESSION_BY_ID_URL, DELETE_CUSTOMER_SESSION_URL, GET_CUSTOMER_SESSION_DETAILS_URL, UPDATE_CUSTOMER_SESSION_URL, SYNC_SESSION_WITH_CONTRACT_URL, UPDATE_CUSTOMER_SESSION_BY_ID_URL, UPDATE_SESSION_WITH_LOGGED_IN_USER_URL
} from "./url.service";

export const createCustomer = (data) => {
    return HttpService.postWithAuth(CREATE_CUSTOMER_URL, { userData: data });
};

export const createCustomerAddress = (data) => {
    return HttpService.postWithAuth(CREATE_CUSTOMER_ADDRESS_URL, data);
};

export const updateCustomerAddress = (data, customerId) => {
    return HttpService.putWithAuth(UPDATE_CUSTOMER_ADDRESSES({ customerId }), data);
};

export const updateCustomerDateAndAddress = (data, customerId, contractId) => {
    return HttpService.putWithAuth(UPDATE_CUSTOMER_DATE_AND_ADDRESSES({ customerId, contractId }), data);
};

export const updateCollectionAddressAndDate = (data, contractId) => {
    return HttpService.putWithAuth(UPDATE_COLLECTION_ADDRESS_AND_DATE_URL({ contractId }), data);
};

export const getDefaultAddresses = ({ customerId, type = 'Delivery' }) => {
    return HttpService.getWithOutAuth(GET_DEFAULT_CUSTOMER_ADDRESSES_URL({ customerId, type }));
};

export const getLatestAddressMobile = ({ customerId }) => {
    return HttpService.getWithOutAuth(GET_LATEST_CUSTOMER_ADDRESSE_MOBILE_URL({ customerId }));
};


export const getHomeBillingOrDeliveryAddresses = ({ customerId, contractId, accessToken }) => {
    return HttpService.getAuthWithAccessToken(GET_HOME_BILLING_OR_DELIVERY_ADDRESSES_URL({ customerId, contractId }), accessToken);
};

export const getCustomerDataById = (customerId) => {
    return HttpService.getWithAuth(GET_CUSTOMER_BY_ID_URL({ customerId }));
};

export const updateCustomer = (data, customerId) => {
    return HttpService.putWithAuth(UPDATE_CUSTOMER_URL({ customerId }), { userData: data });
};

export const getCustomerById = (customerId, accessToken) => {
    return HttpService.getAuthWithAccessToken(GET_CUSTOMER_BY_ID_URL({ customerId }), accessToken);
};

export const getCustomerContractContact = ({ contractId, customerId }, accessToken) => {
    return HttpService.getAuthWithAccessToken(GET_CUSTOMER_CONTRACT_CONTACT_URL({ contractId, customerId }), accessToken);
};

export const sendOtp = (customerId, data) => {
    return HttpService.postWithAuth(SEND_OTP_WITH_MOBILE_URL(customerId), data);
};

export const verifyOtp = (customerId, data) => {
    return HttpService.postWithAuth(VERIFY_OTP_WITH_MOBILE_URL(customerId), data);
};

export const getDrivingLicenseDetails = (customerId, accessToken) => {
    return HttpService.getAuthWithAccessToken(GET_DRIVING_LICENSE_DETAILS_URL(customerId), accessToken);
};

export const updateDrivingLicenseDetails = (id, data) => {
    return HttpService.putWithAuth(UPDATE_DRIVING_LICENSE_DETAILS_URL(id), data);
};

export const createCustomerSession = (data) => {
    return HttpService.postWithAuth(CREATE_CUSTOMER_SESSION_URL, { userSessionData: { ...data, expiresAt: new Date(new Date().getTime() + 24 * 60 * 60 * 1000) } });
};

export const getCustomerSession = (sessionId) => {
    return HttpService.getWithOutAuth(GET_CUSTOMER_SESSION_BY_ID_URL({ sessionId }));
};

export const deleteCustomerSession = (sessionId) => {
    return HttpService.deleteWithOutAuth(DELETE_CUSTOMER_SESSION_URL({ sessionId }));
};

export const getCustomerSessionDetails = (customerId) => {
    return HttpService.getWithOutAuth(GET_CUSTOMER_SESSION_DETAILS_URL({ customerId }));
};

export const updateCustomerSessionDetails = (sessionDetails) => {
    return HttpService.postWithAuth(UPDATE_CUSTOMER_SESSION_URL({ email: sessionDetails.email }), { sessionDetails });
};

export const updateSessionWithLoggedInUser = (updateData, accessToken, countryIsoCode) => {
    return HttpService.putAuthWithAccessToken(UPDATE_SESSION_WITH_LOGGED_IN_USER_URL, accessToken, updateData, countryIsoCode);
};

export const updateCustomerSessionById = ({ sessionId, data, action }) => {
    return HttpService.putWithOutAuth(UPDATE_CUSTOMER_SESSION_BY_ID_URL({ sessionId }), { sessionDetails: data, action });
};

export const syncSessionWithContract = ({ sessionDetails, sessionId }, countryCode = 'DE') => {
    return HttpService.postWithOutAuth(SYNC_SESSION_WITH_CONTRACT_URL({ sessionId }), { sessionDetails }, countryCode);
};