import PropTypes from "prop-types";
import React from "react";

const Text = ({
  variant = "",
  fontSize = "",
  fontWeight = "font-normal",
  textColor = "text-neutral-900",
  children,
  className,
  onClick,
  ...all
}) => {
  const TextType = {
    body: "body",
    bodySmall: "bodySmall",
    bodyLarge: 'bodyLarge',
    caption: "caption",
  };

  const TextStyle = {
    body: "text-sm sm:text-base",
    bodyLarge: 'text-base md:text-[1.125rem]',
    bodySmall: "text-sm",
    caption: "text-xs",
    xxs: "text-[10px]",
  };
  return (
    <p
      variant={TextType[variant]}
      {...all}
      className={`${fontSize ? fontSize : TextStyle[variant]} ${fontWeight} ${textColor} ${fontSize}  ${className}`}
      onClick={onClick}
    >
      {children}
    </p>
  );
};

export default Text;

Text.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.node,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  textColor: PropTypes.string,
  className: PropTypes.string,
};
