import BackButton from "organisms/BackButton";
import Header from "organisms/Header";


const AccountLayout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header type="homeScreen" />
      <BackButton />
      {children}
    </div>
  );
};

export default AccountLayout;
