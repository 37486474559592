import * as R from "ramda";
import { getCountryCallingCode } from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en';
import { removeCouponFromCookies } from "./coupons.service";
import { getCustomerSession } from "./customer.service";
import { countries } from "constants/country-locale-list";
import { getCurrencyIsoCode, getPlanAmount, getUserCountry, getUserCurrency, getUserLocale, removeSessionId } from "./locale.service";
import { getUpcomingInvoice } from "./stripe.service";


export function handleApiResponse(res, type = "object", defaultErrMsg = "Something went wrong!") {
    let details = {};
    let apiError = [];
    if (R.pathOr(false, ["status"], res)) {
        const defaultRes = type === "object" ? {} : [];
        details = R.pathOr(defaultRes, ["entity"], res);
    } else {
        apiError = R.pathOr([defaultErrMsg], ["message"], res);
        if (typeof apiError === "string") {
            apiError = [apiError];
        }
    }

    return { details, apiError, statusCode: (res?.statusCode || null) };
}


export function getParsedValue(data) {
    try {
        return JSON.parse(data);
    } catch {
        return null
    }
}


export function getTimeSlots() {
    const slots = [
        {
            id: 1,
            startTime: 8,
            endTime: 12
        },
        {
            id: 2,
            startTime: 12,
            endTime: 16
        },
        {
            id: 3,
            startTime: 16,
            endTime: 20
        }
    ]

    return slots;
}

export function getFormattedAddress(address) {
    return `${address.line1}, ${(address.line2 || '') ? address.line2 + ', ' : ''}${(address.city || '') ? address.city + ', ' : ''}${(address.state || '') ? address.state + ', ' : ''}${address.postal_code}`
}

export function getPlansSubHeading(planAmount, onGoingPlanAmount) {
    if (onGoingPlanAmount > planAmount)
        return `You'll pay ${onGoingPlanAmount - planAmount} less every month`
    return `You'll pay ${planAmount - onGoingPlanAmount} more every month`
}

export function replaceDescription(description, currentPlan) {
    description = description
        .replace("{included_mileage}", currentPlan?.includedMileage)
        .replace("{insurance_excess}", getPriceWithCurrency({ price: currentPlan?.insuranceExcess, currency: currentPlan?.currency }))
        .replace("{cost_per_extra_mileage}", getPriceWithCurrency({ price: currentPlan?.costPerExtraMileage, removeDecimal: false, currency: currentPlan?.currency }))
        .replace(
            "{mileage_in_kilometer_or_miles}",
            currentPlan?.mileageKilometerMiles
        );
    return description;
};


export function getTodayDate(date = null) {
    let todayDate = new Date().toISOString();
    if (date) todayDate = new Date(date).toISOString();
    return todayDate.split('T')[0];
}

export function getDateAsYYYYMMDD(date) {
    if(!date) return null;

    let dateInstance = new Date(date);
    const onlyDate = dateInstance.getDate();

    return `${dateInstance.getFullYear()}-${dateInstance.getMonth() + 1}-${onlyDate < 9 ? '0' + onlyDate : onlyDate}`
}

export function getCheckList({ subscriptionDetails, additionalDriverDetails = {} }) {
    let mobile = subscriptionDetails?.ContractContactVersions[0]?.Mobile;
    if (subscriptionDetails.AdditionalDriver?.type === 'Driver') {
        mobile = subscriptionDetails?.AdditionalDriver?.Mobile
    }


    return {
        "advance_payment": {
            icon: "/images/payment.svg",
            verified:
                subscriptionDetails?.Payments[0]?.status === "succeeded"
                    ? true
                    : false,
            redirectUrl: `/subscription-dashboard/${subscriptionDetails?.id}?modal=advance_payment`,
        },
        "driving_license_verification": {
            icon: "/images/security-card.svg",
            redirectUrl: `/subscription-dashboard/${subscriptionDetails?.id}?modal=verify-identity`,
        },
        "mobile_number_verification": {
            icon: "/images/call-outgoing.svg",
            redirectUrl: `/subscription-dashboard/${subscriptionDetails?.id}?modal=mobile_number_verification`,
        },
        "home_billing_address_verification": {
            icon: "/images/location.svg",
            verified: subscriptionDetails?.Customer?.CustomerAddresses.filter(ad => ad.type !== 'Delivery').every(ad => ad.isVerified),
            redirectUrl: `/subscription-dashboard/${subscriptionDetails?.id}?modal=confirm-address`,
        },
        "delivery_date_delivery_address_verification":
        {
            icon: "/images/truck-time.svg",
            verified: subscriptionDetails?.Customer?.CustomerAddresses.filter(ad => ad.type === 'Delivery').every(ad => ad.isVerified),
            redirectUrl: `/subscription-dashboard/${subscriptionDetails?.id}?modal=confirm-delivery-address`,
        },
        "additional_driver_confirmation": {
            icon: "/images/payment.svg",
            verified: additionalDriverDetails.status === "Upcoming" ? false : true,
            redirectUrl: `/subscription-dashboard/${subscriptionDetails?.id}?modal=driver-invitation`,
        },
    };
}

export const getFormattedTime = (timeInHour) => timeInHour > 12 ? `${timeInHour - 12} PM` : (timeInHour == 12 ? `${timeInHour} PM` : `${timeInHour} AM`);

export const getCancellationReasons = () => {
    const reasons = [
        {
            label: 'Price is too expensive and I cannot afford any longer',
            value: 'Price is too expensive and I cannot afford any longer'
        },
        {
            label: 'Service is bad and I am switching to another provider',
            value: 'Service is bad and I am switching to another provider'
        },
        {
            label: 'I am not happy with the car',
            value: 'I am not happy with the car'
        },
        {
            label: 'I am moving to a different location',
            value: 'I am moving to a different location'
        },
        {
            label: 'I am not happy with Subscription',
            value: 'I am not happy with Subscription'
        },
        {
            label: 'I am leasing a car now',
            value: 'I am leasing a car now'
        },
        {
            label: 'I am buying a car now',
            value: 'I am buying a car now'
        },
        {
            label: 'I got a company car',
            value: 'I got a company car'
        },
        {
            label: 'I got a leasing car from the company as part of my job',
            value: 'I got a leasing car from the company as part of my job'
        },
        {
            label: 'Other',
            value: 'Other'
        },
    ]

    return reasons;
}

export function getStripeAEAddressFormat(address) {
    return {
        line1: address.addressLine1,
        line2: address.addressLine2,
        city: address.city,
        state: address.state,
        postal_code: address.zipcode,
        country: address.country,
    }
}

export function getDbAddressFormat({ stripeAddress, type }) {
    return ({
        addressLine1: stripeAddress?.line1,
        addressLine2: stripeAddress?.line2,
        city: stripeAddress?.city,
        state: stripeAddress?.state,
        zipcode: stripeAddress?.postal_code,
        country: stripeAddress?.country,
        type
    })
}

export function getCustomerCollectionAddress(cv) {
    if (cv.customerCollectionAddress) return cv.customerCollectionAddress;

    if (cv.customerDeliveryAddress) return cv.customerDeliveryAddress;

    return ({});

}

export function getCheckListVerificationStatus(checkListData) {
    const { ChecklistDetail: { availableForBackoffice, availableForCustomer }, backofficeVerifiedStatus, customerVerifiedStatus } = checkListData;
    const isAvailableForBoth = (availableForBackoffice && availableForCustomer);
    if (isAvailableForBoth) {
        const isBothVerified = (backofficeVerifiedStatus && customerVerifiedStatus);
        return ({
            isVerified: isBothVerified,
            status: isBothVerified ? 'Verified' : (!customerVerifiedStatus ? 'Requires Action' : 'Verification in Progress'),
            inProgress: isBothVerified ? false : (!customerVerifiedStatus ? false : true)
        })
    }

    return ({
        isVerified: customerVerifiedStatus,
        status: customerVerifiedStatus ? 'Verified' : 'Requires Action',
        inProgress: false
    })
}

export function getSubscriptionBackgroundHeading(step, isMobileScreen = false) {
    switch (step) {
        case 1:
            return "Choose your subscription";
        case 2:
            return "Your details";
        case 3:
            return "Delivery details";
        case 4:
            return "Payment details";
        default:
            return "Unknown step";
    }
}

export function hasAllNonEmptyValues(arr) {
    if (!Array.isArray(arr)) {
        return false;
    }

    for (const value of arr) {
        if (value === null || value === undefined || value === "") {
            return false;
        }
    }

    return true;
}

export const getAddressFormatByStatus = (versions, status = 'Active', type) => {
    let address;
    if (versions.length > 1) {
        const cv = versions.find(currencv => currencv.status == status);

        if (cv && cv[type]) address = cv[type];
    } else {
        address = versions[0][type];
    }


    if (!address) return '';

    return address.addressLine1 + ", " + address.city + ", " + address.state + ", " + address.zipcode + ", " + address.country;
}

export const formatTCountdownTimer = (secs) => {
    const minutes = Math.floor(secs / 60);
    const remainingSeconds = secs % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
};

export function getPriceWithCurrency({ price, removeDecimal = true, currency: currencyP }) {
    const countryIsoCode = getUserCountry();
    const currency = currencyP || getCurrencyIsoCode();

    return new Intl.NumberFormat(
        `en-${countryIsoCode.toUpperCase()}`,
        { style: 'currency', currency: currency.toUpperCase(), trailingZeroDisplay: "stripIfInteger", ...(removeDecimal ? { maximumFractionDigits: 0 } : {}) }
    ).format(price);
}

export function removeAllSavedStorageData() {
    localStorage.removeItem('clientSecret');
    localStorage.removeItem("user-details");
    localStorage.removeItem("user-logged-in-details");
    localStorage.removeItem('redirect-url-after-login');
    localStorage.removeItem("user-data");
    localStorage.removeItem("userEmail");
    localStorage.removeItem('client-secret');
    localStorage.removeItem('productImg');
    removeSessionId();
    removeCouponFromCookies();
}

export const enWithCode = Object.keys(en).reduce((acc, c) => {
    const country = en[c];
    let countryCode;
    try {
        countryCode = getCountryCallingCode(c);
    } catch (err) {
        countryCode = null;
    }
    if (countryCode) {
        acc[c] = `${country} (+${countryCode})`
    } else {
        acc[c] = country
    }
    return acc;
}, {});


export function compareAddresses(address1, address2) {
    return (
        address1.addressLine1 === address2.addressLine1 &&
        address1.addressLine2 === address2.addressLine2 &&
        address1.city === address2.city &&
        address1.state === address2.state &&
        address1.zipcode === address2.zipcode &&
        address1.country === address2.country
    );
}

export const formatAddress = ({ addressLine1, addressLine2 = "", city, country, state, zipcode }) => {
    let regionNames = new Intl.DisplayNames(['en'], {type: 'region'});
    let formattedAddress = '';

    if (addressLine1) {
        formattedAddress += ` ${addressLine1}, `;
    }

    if (addressLine2) {
        formattedAddress += ` ${addressLine2}, `;
    }

    formattedAddress += ` ${city ? city +', ' : ''}${state ? state +', ' : ''}${zipcode ? zipcode +', ' : ''}${regionNames.of(country) || ''}`;

    return formattedAddress;
}

export const getPriceBreakupData = async ({ stripeProductPriceId, stripePlanPriceId, country, coupon }) => {
    try {
        const getUpcomingInvoiceResponse = await getUpcomingInvoice({ stripeProductPriceId, stripePlanPriceId, country, coupon });
        const { details: invoiceDetails } = await handleApiResponse(getUpcomingInvoiceResponse);


        const { tax, subtotal, total_excluding_tax, total, total_discount_amounts: [discountData], tax_rate: taxRate } = invoiceDetails;
        return ({
            netTax: parseFloat(tax / 100).toFixed(2),
            subtotal: parseFloat(subtotal / 100).toFixed(2),
            totalExcludingTax: parseFloat(total_excluding_tax / 100).toFixed(2),
            totalAmount: parseFloat(total / 100).toFixed(2),
            discountAmount: discountData ? parseFloat(discountData.amount / 100).toFixed(2) : 0,
            taxRateDetail: taxRate ? `${taxRate.display_name} - ${taxRate.jurisdiction} ${taxRate.effective_percentage}% ${taxRate.inclusive ? 'inclusive on ' : 'exclusive of '} ${getUserCurrency(parseFloat(total_excluding_tax / 100).toFixed(2))}` : ''
        })
    } catch (err) {
        console.log('Error while loading price breakup service: ', err);
        return null;
    }
}


export const getCarName = (activeCvWithPlanProduct) => {
    const cvProduct = activeCvWithPlanProduct?.ContractVersionBp;

    return cvProduct?.BaseProduct?.fullName;
}

export const redirectPropsToCatalogPage = () => {
    const country = getUserCountry();
    const lang = getUserLocale();

    return ({
        redirect: {
            permanent: false,
            destination: `/${lang}/${country.toLowerCase()}/all-cars`
        }
    });
}

export const redirectToCatalogPageWhenSessionExpired = async (sessionId) => {
    const res = await getCustomerSession(sessionId);

    const { details, apiError } = handleApiResponse(res);

    const country = getUserCountry();

    return ({
        status: apiError.length,
        redirect: {
            permanent: false,
            destination: `/en/${country.toLowerCase()}/all-cars`
        },
        details: (details || null)
    });
}

export const redirectToCatalogPageWhenWrongCountry = ({ userCountry, cookieCountry, cookieLocale }) => {
    const country = cookieCountry || 'DE';
    const lang = cookieLocale || 'en';

    const validCountry = countries.find(c => c.value == userCountry);

    return ({
        status: validCountry,
        redirect: {
            permanent: false,
            destination: `/${lang}/${country.toLowerCase()}/all-cars`
        }
    });
}

export function getDiffDays(firstDateMs, secondDateMs) {
    return Math.ceil(Math.abs(firstDateMs - secondDateMs) / (1000 * 60 * 60 * 24));
}


export const getRemainingDaysFromToday = (deliveryDate) => {
    const dateInMs = new Date(deliveryDate).getTime();
    const todayDateInMs = new Date().getTime();
    return getDiffDays(dateInMs, todayDateInMs);
}

export const getPlanData = (planData) => {
    if (planData?.length > 0) {
        let plans = planData.map((plan) => {
            return {
                planName: Array.isArray(plan?.BPPlan?.BPPlan_i18n) && plan?.BPPlan?.BPPlan_i18n.length ? plan?.BPPlan?.BPPlan_i18n[0]?.planName : "",
                isSelected: false,
                planDetails: plan.BPPlan.BPPlanDescriptions.map((detail) => {
                    return {
                        id: detail.id,
                        title: replaceDescription(detail.BPPlanDescription_i18n[0]?.description || "", {
                            insuranceExcess: plan?.BPPlan?.BPPlanPricebookLineItems[0]?.insuranceExcess,
                            includedMileage: plan?.BPPlan?.BPPlanPricebookLineItems[0]?.includedMileage,
                            costPerExtraMileage: plan?.BPPlan?.BPPlanPricebookLineItems[0]?.costPerExtraMileage,
                            mileageKilometerMiles: plan?.BPPlan?.BPPlanPricebookLineItems[0]?.mileageUnit,
                            currency: plan?.BPPlan?.BPPlanPricebookLineItems[0].BPPlanPricebook.currencyIsoCode
                        })
                    }
                }),
                currency: plan?.BPPlan?.BPPlanPricebookLineItems[0].BPPlanPricebook.currencyIsoCode,
                planAmount: getPlanAmount(plan?.BPPlan?.BPPlanPricebookLineItems),
                planSlug: plan?.BPPlan?.slug,
                id: plan?.BPPlan ? plan?.BPPlan?.id : "",
                stripePriceId: plan?.BPPlan ? plan?.BPPlan?.BPPlanPricebookLineItems[0].stripePriceId : ""
            };
        });
        return plans
    }
    return []

}

export function parseDateString(dateString) {
    if(!dateString) return null;
    
    const parts = dateString.split(/[-/]/);
  
    const year = parseInt(parts[0]);
    const month = parseInt(parts[1]) - 1; // Months are zero-based in JavaScript
    const day = parseInt(parts[2]);
  
    const parsedDate = new Date(year, month, day);
  
    if (isNaN(parsedDate.getTime())) {
      // Invalid date
      return null;
    }
  
    return parsedDate;
  }