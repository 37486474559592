import * as HttpService from "./http.service";
import { CREATE_STRIPE_SETUP_INTENT_URL, CREATE_STRIPE_SUBSCRIPTION_URL, GET_ADVANCE_PAYMENT_AND_ASSOCIATED_P_METHODS, GET_STRIPE_SETUP_INTENT_URL, GET_UPCOMING_INVOICE_DETAILS, CREATE_STRIPE_IDENTITY_VERIFICATION_SESSION_URL, GET_COUPON_DETAIL, APPLY_COUPON_SUBSCRIPTION, GET_STRIPE_SUBSCRIPTION_URL, REMOVE_COUPON_SUBSCRIPTION, MARK_AS_DEFAULT_PAYMENT_METHOD_STRIPE_URL, DETACH_PAYMENT_METHOD_STRIPE_URL, GET_FAILED_PAYMENTS_AND_CUSTOMERS_PM, GET_PAYMENT_DETAIL_AND_CUSTOMER_PM, GET_FAILED_PAYMENT_INTENT_URL } from "./url.service";

export const createSubscription = (subscriptionData, countryCode = 'DE') => {
    return HttpService.postWithOutAuth(CREATE_STRIPE_SUBSCRIPTION_URL, subscriptionData, countryCode);
};

export const createSetupIntent = (data) => {
    return HttpService.postWithAuth(CREATE_STRIPE_SETUP_INTENT_URL, data);
};

export const getSetupIntent = ({ setupIntentId, customerId, contractId }) => {
    return HttpService.getWithAuth(GET_STRIPE_SETUP_INTENT_URL({ setupIntentId, customerId, contractId }));
};

export const getUpcomingInvoice = (data) => {
    return HttpService.postWithOutAuth(GET_UPCOMING_INVOICE_DETAILS, data);
};

export const getAdvancePaymentAndAssociatedPayMethods = ({ customerId, contractId }, accessToken) => {
    return HttpService.getAuthWithAccessToken(GET_ADVANCE_PAYMENT_AND_ASSOCIATED_P_METHODS({ customerId, contractId }), accessToken);
};

export const getFailedPaymentIntent = ({ customerId, contractId, paymentId }, accessToken, country) => {
    return HttpService.getAuthWithAccessToken(GET_FAILED_PAYMENT_INTENT_URL({ customerId, contractId, paymentId }), accessToken, country);
};

export const getPaymentDetailAndCustomersPm = ({ paymentId }, accessToken) => {
    return HttpService.getAuthWithAccessToken(GET_PAYMENT_DETAIL_AND_CUSTOMER_PM({ paymentId }), accessToken);
};

export const getFailedPaymentsAndCustomersPm = ({ customerId, contractId }, accessToken) => {
    return HttpService.getAuthWithAccessToken(GET_FAILED_PAYMENTS_AND_CUSTOMERS_PM({ customerId, contractId }), accessToken);
};

export const createIdentityVerificationSession = (data) => {
    return HttpService.postWithAuth(CREATE_STRIPE_IDENTITY_VERIFICATION_SESSION_URL, data);
}

export const getCouponDetails = ({ couponId }) => {
    return HttpService.getWithOutAuth(GET_COUPON_DETAIL(couponId))
}

export const applyCouponSubscription = (data) => {
    return HttpService.postWithAuth(APPLY_COUPON_SUBSCRIPTION, data);
}

export const getStripeSubscriptionDetails = ({ stripeSubscriptionId }) => {
    return HttpService.getWithOutAuth(GET_STRIPE_SUBSCRIPTION_URL(stripeSubscriptionId));
};

export const removeCouponSubscription = (data) => {
    return HttpService.postWithAuth(REMOVE_COUPON_SUBSCRIPTION, data);
}

export const markAsDefaultPaymentMethod = (data) => {
    return HttpService.postWithAuth(MARK_AS_DEFAULT_PAYMENT_METHOD_STRIPE_URL, data);
}
export const detachPaymetMethod = (data) => {
    return HttpService.postWithAuth(DETACH_PAYMENT_METHOD_STRIPE_URL, data);
}
