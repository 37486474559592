import { getProductAmount, getUserCurrency } from "./locale.service";
import { countries, localeData } from "constants/country-locale-list";

export const emailRegex = (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

export const getCarDetailPageTitle = (baseProduct) => {

    const { metaTitle, fullName } = baseProduct;
    const title = (metaTitle || '');

    const price = getProductAmount(baseProduct?.BPPricebookLineItems);

    const productAmountWithCurrency = getUserCurrency(price);

    return title.replace(/\{carFullName\}/g, fullName).replace(/\{startingPrice\}/g, productAmountWithCurrency);
}

export const getCountryLabel = (country, locale) => {
    const entry = localeData.find((data) => data.locale === locale && data.countryIsoCode === country);
    return entry ? entry.label : '';
}

export const getCarDetailPageDescription = (baseProduct, country, locale) => {

    const { metaDescription, fullName } = baseProduct;
    const description = (metaDescription || '');

    const price = getProductAmount(baseProduct?.BPPricebookLineItems);

    const productAmountWithCurrency = getUserCurrency(price);

    const countryLabel = getCountryLabel(country, locale);
    return description.replace(/\{carFullName\}/g, fullName).replace(/\{startingPrice\}/g, productAmountWithCurrency).replace(/\{country\}/g, countryLabel);
}

export function addProductJsonLd(baseProduct) {
    return {
        __html: JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": baseProduct.fullName,
            "image": baseProduct.BPImageUrls.map((img) => img.imageUrl),
            "description": baseProduct.metaDescription,
            "model": baseProduct.model,
            "bodyType": baseProduct.bodyType,
            "fuelType": baseProduct.fuelType,
            "brand": {
                "@type": "Brand",
                "name": baseProduct.make
            }
        }),
    };
}

export const getAllCarsPageTitle = (title, country) => {
    const countryLabel = countries.find((x) => x.value === country.toUpperCase())?.label;
    return title.replace(/\{country\}/g, countryLabel);
}