import React from 'react'
import Header from 'organisms/Header';
import Footer from 'organisms/Footer';
import Heading from 'atoms/Heading';
import Button from 'atoms/Button';
import Image from 'atoms/Image';

const NotFound = ({ showHeader = true }) => {
    return (
        <div className='h-[100dvh] flex flex-col'>
            {
                showHeader && <Header className='h-[70px] md:h-[73px] flex-none' />
            }
            <div className='h-[65%] bg-primary-200 px-5 py-[30px] md:px-[100px] xl:px-[200px] md:pt-[5%] relative flex flex-col justify-between md:block'>
                <div>
                    <Heading type='h1' fontSize='text-[32px] md:text-[50px]' className='text-black font-semibold  mb-2.5 md:mb-[50px]'>Oops! Looks like you're lost...</Heading>
                    <Heading type='h6' fontSize='text-lg md:text-xl' className='text-neutral-700 font-semibold hidden md:block'>We'll get you back on the road in no time - head to our home</Heading>
                    <Heading type='h6' fontSize='text-lg md:text-xl' className='text-neutral-700 font-semibold mb-6 hidden md:block'>to explore lots of great mobility products and content.</Heading>
                    <Heading type='h6' fontSize='text-lg md:text-xl' className='text-neutral-700 md:font-semibold mb-6 block md:hidden'>We'll get you back on the road in no time - head to our home to explore lots of great mobility products and content.</Heading>
                    <Button
                        id="home"
                        className="px-[86px] pb-2 pt-2.5 hidden md:block"
                        onClick={() => window.open("/", "_self")}
                        fontSize="text-lg"
                        style="primary"
                        rounded="rounded-br-[20px]"
                        classForBtnText='mt-0.5'
                        btnText="Home"
                    />
                </div>

                <Button
                    id="home"
                    className="flex md:hidden justify-center items-center gap-x-5 mb-5"
                    onClick={() => window.open("/", "_self")}
                    fontSize="text-xl"
                    style="primary"
                    size="extraLarge"
                    btnText="Home"
                    classForBtnText='mt-0.5'
                    fullWidth={true}
                />
                <Image imgSrc='/images/curve-2.svg' imgAlt='curve' className='hidden md:block absolute right-0 top-[30%]' />
            </div>
            <div className='h-[35%] bg-secondary-60 pb-[30px] px-5 md:px-[100px] xl:px-[200px] flex flex-col justify-end md:justify-center relative'>
                <Heading type='h6' fontSize='text-xl' className='text-neutral-700 font-semibold hidden md:block'>Want to speak to one of our team?</Heading>
                <Heading type='h6' fontSize='text-xl' className='text-neutral-700 font-semibold  underline hover:no-underline transition-all duration-300 cursor-pointer hidden md:block'>Get in touch  </Heading>
                <Heading type='h6' fontSize='text-xl' className='text-neutral-700 font-semibold block md:hidden'>Want to speak to one of our team? <span className='underline hover:no-underline transition-all duration-300' onClick={() => { }}>Get in touch</span></Heading>

                <Image imgSrc='/images/four-cars.svg' imgAlt='four cars' className='hidden lg:block max-w-[800px] w-full h-[430px] absolute -top-[200px] right-0' />
                <Image imgSrc='/images/three-cars.svg' imgAlt='three cars' className='block lg:hidden max-w-[450px] w-[90%] h-[466px] absolute -top-[200px] right-0' />
            </div>
            <Footer type='type2' />
        </div>
    )
}

export default NotFound